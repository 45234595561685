const contact = [


    {
        nombre: 'Mamis Vip México',
        telefono: 54387583101,
        email: 'contacto@mamisvip.com',
        direccion: 'Mexico',
        ceo: 'Eduardo',
        dominio: 'www.putasmexico.net',
        nosotros: `Mamis VIP México somos una plataforma de hospedaje web para ANUNCIOS CLASIFICADOS PORNO verificados que conecta de forma independiente al Usuario con el Anunciante desde el año 2009 con sede en Victoria, Seychelles para la comunidad mexicana.`
    }

]
export default contact