import React from 'react'
import Nosotros from '../../Components/Nosotros/Nosotros'
import BgPageSection from '../../Components/BgPageSection/BgPageSection'
export default function NosotrosPage() {
    return (
        <div>
            <BgPageSection />

            <Nosotros />
        </div>
    )
}
