const dominios = [

    'www.putasmexico.net',
    'www.Mamisvip.com',
    'www.EscortsMamisvip.online',
    'www.Mamisvip.shop',
    'Escorts.Mamisvip.com',
    'Putas.Mamisvip.com',
    'Putasmexico.Mamisvip.com',
    'Putasvip.Mamisvip.shop	',
    'Escortsvip.Mamisvip.shop',
    'Eroticasvip.Mamisvip.shop',
    'Scortsvip.Escortsmamisvip.online',
    'Putitas.Escortsmamisvip.online	',
    'Platinum.Escortsmamisvip.online',
    'Putasmexico.Escortsmamisvip.online',
    'Escortsmexico.Escortsmamisvip.online',

];

export default dominios;
