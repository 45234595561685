const palabrasClave = [
    '18',
    'Ninfómana',
    'Nalgona',
    'Traviesa',
    'Domicilio',
    'Con lugar',
    'Gordibuena',
    'Madura',
    'Señora',
    'Económico',
    'Anal',
    'Scorts',
    'Gordita',
    '🔥🔥🔥',
    '🍑🍑🍑',
    'Gratis',
    'Maduras',
    'Whatsapp',
    'Independiente',
    'Sexo anal',
    'Chaparrita',
    'Milf',
    'Escorts',
    'Madurita',
    'Chichona',
    'Delgada',
    'Tetona',
    'Flaquita',
    'Oriente',
    'Morena',
    'Lugar',
    'Embarazada',
    'Oral',
    'Compañía',
    'Telegram',
    'Colombiana',
    'Mañana',
    '2 horas',
    'Garantizadas',
    'Cachonda',
    'Palo alto',
    'Fotos',
    'Oral mutuo',
    'Gay',
    'Lesbiana',
    'Caliente',
    'Discreta',
    'Atractiva',
    'Experta',
    'Sensual',
    'Disponible',
    'Satisfacción',
    'Relajante',
    'Exclusiva',
    'Bisexual',
    'Trans',
    'Parejas',
    'Erotismo',
    'Travesti',
    'Morochita',         // Nuevas palabras agregadas
    'Rubia',
    'Latina',
    'Europea',
    'Exótica',
    'Masajes',
    'Joven',
    'Madurita caliente',
    'Sensualidad',
    'Acompañante',
    'Candente',
    'Hot',
    'Cuerpazo',
    'Figura perfecta',
    'Atención personalizada',
    'Cuerpazo de modelo',
    'Chica universitaria',
    'Disponible ya',
    'Solo hoy',
    'Servicio completo',
    'Abierta',
    'VIP',
    'Cariñosa',
    'Novedad',
    'Amigable',
    'Primera vez',
    'Interesada',
    'Sexy',
    'Exclusividad garantizada',
    'De lujo',
    'Impresionante',
    'Natural'
];

export default palabrasClave;
